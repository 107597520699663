<template>
  <v-row class="mb-15 fill-height">
    <v-col>
      <v-row>
        <v-col>
          <h1>Reporte Redenciones Club de Beneficios</h1>
        </v-col>
      </v-row>
      <v-row style="padding-left: 30px; margin-top: 25px"> </v-row>
      <v-row class="mb-15" style="padding-left: 30px">
        <v-col md="3">
          <h3>Selecciona el rango de fechas</h3>
          <v-date-picker
            style="width: 100%"
            v-model="dates"
            range
          ></v-date-picker>
          <v-btn
            rounded
            color="primary"
            style="width: 100%"
            dark
            @click="consultar(false)"
            >Consultar</v-btn
          >
          <v-btn
            rounded
            color="primary"
            style="width:100%;margin-top:20px;"
            :disabled="bloquear"
            @click="consultar(true)"
          >
            Descargar Información
          </v-btn>
        </v-col>
        <v-col cols="9" md="9">
          <div>
            <div v-if="loading" style="text-align: center">
              <h2 clas="pb-4">Cargando Reporte....</h2>
              <br /><br />
              <md-progress-spinner
                :md-diameter="100"
                :md-stroke="10"
                md-mode="indeterminate"
              />
            </div>
          </div>

          <v-data-table
            :headers="columns"
            :items="leads"
            class="elevation-1"
            :server-items-length="totalRegistros"
            :loading="loading"
            loading-text="Cargando..."
            :items-per-page="elementosPagina"
            @update:page="pagina = $event"
            @update:items-per-page="elementosPagina = $event"
          >
            <!-- <template v-slot:item.urlPoliza="{ item }">
                                    <v-btn :href="'https://segurify-data.s3.amazonaws.com/' + item.urlPoliza">Descargar</v-btn>
                                </template> -->
          </v-data-table>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
var moment = require("moment-timezone");
import download from "downloadjs";
import { ListExpanded } from "../../../../plugins/mixins";

export default {
  mixins: [ListExpanded],

  data() {
    return {
      dates: ["", ""],
      roles: JSON.parse(localStorage.getItem("roles")),
      agenteUuid: localStorage.getItem("agenteUuid"),
      loading: false,
      reportType: "lead",
      leads: [],
      reportDownloadIt: [],
      reportCompleteDowload: [],
      bloquear: true,
      block: true,
      canal: null,
      canales: [
        { id: 1, name: "AGENTES" },
        { id: 2, name: "MASIVOS" },
        { id: 3, name: "DIRECTOS" },
      ],

      columns: [
        { text: "No. Redención", value: "id" },
        { text: "Fecha de Redención", value: "fecha_redencion" },
        { text: "Socio", value: "socio" },
        { text: "Beneficio", value: "nombreBeneficio" },
        { text: "Ejecutivo", value: "nombreEjecutivo" },
        { text: "Cliente", value: "nombreCliente" },
        { text: "Periodicidad", value: "periodicidad" },
        { text: "Fechas del Periodo", value: "fechasPeriodo" },
      ],
      pagina: 1,
      elementosPagina: 10,
      totalRegistros: 0,
    };
  },

  watch: {
    pagina(v) {
      !!v && this.consultar();
    },

    elementosPagina(v) {
      !!v && this.consultar();
    },
  },

  methods: {
    convertDate(date) {
      const dateRes = moment(date);
      dateRes.tz("UTC", true);
      return dateRes.tz("America/Mexico_City").format("DD-MM-YYYY HH:mm");
    },

    async consultar(file = false) {
      this.loading = true;

      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      };

      var inicioUTC = null;
      var finUTC = null;

      if (this.dates.length > 0) {
        if (this.dates[1]) {
          inicioUTC = moment
            .utc(this.dates[0])
            .add(6, "hours")
            .format("YYYY-MM-DD HH:mm:ss");
          finUTC = moment
            .utc(this.dates[1])
            .add(6, "hours")
            .add(24, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");
        } else {
          inicioUTC = moment.utc(this.dates[0]).format("YYYY-MM-DD HH:mm:ss");
          finUTC = moment
            .utc(this.dates[0])
            .add(24, "hours")
            .add(6, "hours")
            .subtract(1, "seconds")
            .format("YYYY-MM-DD HH:mm:ss");
        }
      }

      let query = {
        fecha_redencion: [inicioUTC, finUTC],
        elementosPagina: this.elementosPagina,
        pagina: this.pagina,
      };

      let route = `v1/redencion/filtros`;
      if (file) route += "/file";
      let { data } = await this.$axios.post(route, query, config);

      if (file) return this.manageFileResponse(data);

      this.reportDownloadIt = [];
      this.leads = [];

      this.totalRegistros = data.data?.totalRegistros;

      this.leads = data.data?.redenciones?.map((e) => ({
        ...e,
        fechasPeriodo: e.fechasPeriodo.join(" - ")
      }));

      this.loading = false;
      this.bloquear = false;
    },

    manageFileResponse(responseData) {
      download(responseData, `Informe_redenciones.csv`, "application/csv");

      this.loading = false;
    },

    toCurrency(number) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      });
      return formatter.format(number); /* $2,500.00 */
    },
    // Table
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().indexOf(search) !== -1
      );
    },
  },
  computed: {
    blockBoton() {
      return this.canal != null && this.dates != ["", ""];
    },
    canShow() {
      var canView = false;
      var roles = [];
      this.roles.forEach((element) => {
        roles.push(element.rol);
      });
      const rolesToView = ["ADMIN", "DIRECTOR", "FINANZAS"];
      roles.forEach((element) => {
        if (rolesToView.includes(element)) {
          canView = true;
        }
      });
      return canView;
    },
    currentRol() {
      let roles = [];
      this.roles.forEach((element) => {
        if (element.rol != null) {
          roles.push(element.rol);
        }
      });

      if (roles.length > 0) {
        return roles[0];
      } else {
        return "";
      }
    },
  },
};
</script>
